/*
To add new icon to the set:
1) go to the `warehouse/icons/awesome.scss`
2) find required icon
3) copy line to this file
*/
/*
To add new icon to the set:
1) go to the `warehouse/icons/ion.scss`
2) find required icon
3) copy line to this file
*/
/*
To add new icon to the set:
1) go to the `warehouse/icons/thin.scss`
2) find required icon
3) copy line to this file
*/
/*
To add new icon to the set:
1) go to the `warehouse/icons/awesome.scss`
2) find required icon
3) copy line to this file
*/
/*
To add new icon to the set:
1) go to the `warehouse/icons/ion.scss`
2) find required icon
3) copy line to this file
*/
/*
To add new icon to the set:
1) go to the `warehouse/icons/thin.scss`
2) find required icon
3) copy line to this file
*/
.autocomplete {
  position: relative;
  color: #333;
}
.autocomplete__input {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  min-height: 48px;
  border: 2px solid #c3c3c4;
  border-radius: 4px;
  background: #fff;
  padding: 12px 10px;
}
.autocomplete__input > input {
  padding: 0;
  outline: 0;
  border: 0;
  background: transparent;
  flex: 100;
  width: 100%;
  min-width: 100px;
  font-size: inherit;
}
.autocomplete__selected {
  color: #fff;
  background: #B83437;
  margin-right: 8px;
  margin-bottom: 4px;
  padding: 2px 0 2px 8px;
  border-radius: 2px;
}
.autocomplete__remove {
  display: inline-block;
  cursor: pointer;
  padding: 0 8px;
}
.autocomplete__results {
  position: absolute;
  left: 0;
  top: 100%;
  width: 100%;
  max-height: 250px;
  overflow: hidden;
  overflow-y: auto;
  border-radius: 2px;
  background: #fff;
  box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.2);
  z-index: 9;
}
.autocomplete__result {
  cursor: pointer;
  padding: 12px 10px;
  color: #333;
}
.autocomplete__result:hover {
  color: #333;
  background: #e6e6e6;
}
.autocomplete__result_town, .autocomplete__result_type_town {
  font-weight: bold;
}

/*
To add new icon to the set:
1) go to the `warehouse/icons/awesome.scss`
2) find required icon
3) copy line to this file
*/
/*
To add new icon to the set:
1) go to the `warehouse/icons/ion.scss`
2) find required icon
3) copy line to this file
*/
/*
To add new icon to the set:
1) go to the `warehouse/icons/thin.scss`
2) find required icon
3) copy line to this file
*/
.checkbox-list {
  position: relative;
  color: #333;
}
.checkbox-list__text {
  position: relative;
  min-height: 32px;
  line-height: 32px;
  padding: 7px 10px;
  border-radius: 3px;
  border: 2px solid #c3c3c4;
  background: #fff;
  cursor: pointer;
}
.checkbox-list__text:after {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  content: "";
  display: inline-block;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" fill="%23333333"><path d="M31.3 192h257.3c17.8 0 26.7 21.5 14.1 34.1L174.1 354.8c-7.8 7.8-20.5 7.8-28.3 0L17.2 226.1C4.6 213.5 13.5 192 31.3 192z"/></svg>');
  width: 8px;
  height: 16px;
  right: 20px;
}
.checkbox-list__count {
  display: inline-block;
  min-width: 24px;
  height: 24px;
  line-height: 24px;
  text-align: center;
  border-radius: 2px;
  margin-left: 4px;
  color: #fff;
  background: #B83437;
}
.checkbox-list__options {
  display: none;
  position: absolute;
  left: 0;
  top: 100%;
  width: 100%;
  max-height: 250px;
  overflow: hidden;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  border-radius: 2px;
  background: #fff;
  box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.2);
  z-index: 9;
}
.checkbox-list__option {
  position: relative;
  cursor: pointer;
  padding: 12px 10px;
}
.checkbox-list__box {
  position: absolute;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 12px;
  width: 16px;
  height: 16px;
  border: 1px solid #c3c3c4;
  background: #fff;
  border-radius: 2px;
}
.checkbox-list__option.checkbox-list__option_group {
  font-weight: bold;
  text-transform: uppercase;
  cursor: initial;
}
.checkbox-list__option.checkbox-list__option_group > .checkbox-list__box {
  display: none;
}
.checkbox-list__option.checkbox-list__option_checked > .checkbox-list__box {
  border-color: #B83437;
  background: #B83437;
}
.checkbox-list_opened > .checkbox-list__options {
  display: block;
}
.checkbox-list_opened > .checkbox-list__text:after {
  background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" fill="%23333333"><path d="M288.662 352H31.338c-17.818 0-26.741-21.543-14.142-34.142l128.662-128.662c7.81-7.81 20.474-7.81 28.284 0l128.662 128.662c12.6 12.599 3.676 34.142-14.142 34.142z"/></svg>');
}

.dropdown {
  position: relative;
  color: #333;
}
.dropdown__value {
  position: relative;
  min-height: 32px;
  line-height: 32px;
  padding: 7px 40px 7px 12px;
  border-radius: 4px;
  border: 2px solid #c3c3c4;
  background: #fff;
  cursor: pointer;
}
.dropdown__value:after {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  content: "";
  display: inline-block;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" fill="%23333333"><path d="M31.3 192h257.3c17.8 0 26.7 21.5 14.1 34.1L174.1 354.8c-7.8 7.8-20.5 7.8-28.3 0L17.2 226.1C4.6 213.5 13.5 192 31.3 192z"/></svg>');
  width: 8px;
  height: 16px;
  right: 20px;
}
.dropdown__options {
  position: absolute;
  left: 0;
  top: 100%;
  width: 100%;
  max-height: 250px;
  overflow: hidden;
  overflow-y: auto;
  border-radius: 2px;
  background: #fff;
  box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.2);
  z-index: 9;
}
.dropdown__option {
  cursor: pointer;
  padding: 12px;
  color: #333;
}
.dropdown__option:hover {
  color: #333;
  background: #e6e6e6;
}
.dropdown__option.dropdown__option_state_selected {
  color: #fff;
  background: #B83437;
}
.dropdown_state_opened > .dropdown__value:after {
  background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" fill="%23333333"><path d="M288.662 352H31.338c-17.818 0-26.741-21.543-14.142-34.142l128.662-128.662c7.81-7.81 20.474-7.81 28.284 0l128.662 128.662c12.6 12.599 3.676 34.142-14.142 34.142z"/></svg>');
}
.dropdown_state_closed > .dropdown__options {
  display: none;
}
.dropdown_state_opened > .dropdown__options {
  display: block;
}

.switcher {
  display: inline-flex;
  position: relative;
  cursor: pointer;
  align-items: center;
  color: #333;
}
.switcher__control {
  display: inline-block;
  position: relative;
  cursor: pointer;
  width: 48px;
  height: 28px;
  border-radius: 16px;
  background-color: #efeeef;
  transition: background-color 0.3s ease;
}
.switcher__control:after {
  content: "";
  position: absolute;
  top: 4px;
  left: 4px;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: #fff;
  transition: background-color 0.3s ease, left 0.3s ease;
}
.switcher__label {
  display: inline-block;
  margin-left: 12px;
}
.switcher_state_checked > .switcher__control {
  background-color: #B83437;
}
.switcher_state_checked > .switcher__control:after {
  left: 24px;
  background-color: #fff;
}

/*
To add new icon to the set:
1) go to the `warehouse/icons/awesome.scss`
2) find required icon
3) copy line to this file
*/
/*
To add new icon to the set:
1) go to the `warehouse/icons/ion.scss`
2) find required icon
3) copy line to this file
*/
/*
To add new icon to the set:
1) go to the `warehouse/icons/thin.scss`
2) find required icon
3) copy line to this file
*/
.search-bar {
  position: relative;
}
.search-bar__container {
  width: 280px;
  margin: 0 auto;
}
.search-bar__main-options {
  position: relative;
  padding: 16px;
  border-radius: 3px;
  background-color: #fff;
}
.search-bar__main-options .dropdown__value,
.search-bar__main-options .checkbox-list__text,
.search-bar__main-options .autocomplete__input {
  background-color: transparent;
  border: none;
  padding: 0;
}
.search-bar__main-options .autocomplete__input {
  min-height: 32px;
}
.search-bar__main-options .dropdown,
.search-bar__main-options .checkbox-list {
  border-bottom: 1px solid #ececec;
}
.search-bar__main-options .dropdown,
.search-bar__main-options .checkbox-list,
.search-bar__main-options .autocomplete {
  padding: 32px 0 16px;
}
.search-bar__main-options .dropdown:after,
.search-bar__main-options .checkbox-list:after,
.search-bar__main-options .autocomplete:after {
  position: absolute;
  top: 16px;
  left: 0;
  font-size: 11px;
  font-weight: bold;
  color: #333;
}
.search-bar__main-options .dropdown:after {
  content: "STATUS";
}
.search-bar__main-options .checkbox-list:after {
  content: "TYPE";
}
.search-bar__main-options .autocomplete:after {
  content: "LOCATION";
}
.search-bar__secondary-options {
  display: none;
  margin-top: 64px;
  padding: 16px 16px 0;
  background-color: #fff;
  border-radius: 3px;
  border-top: 4px solid #B83437;
}
.search-bar__secondary-options .dropdown__value,
.search-bar__secondary-options .checkbox-list__text {
  border: none;
  background-color: #efefef;
  border-radius: 2px;
  min-height: 24px;
  line-height: 24px;
}
.search-bar__secondary-options .switcher {
  display: flex;
  margin-top: 16px;
}
.search-bar__secondary-options .dropdown__value,
.search-bar__secondary-options .dropdown__options,
.search-bar__secondary-options .checkbox-list__text,
.search-bar__secondary-options .checkbox-list__options,
.search-bar__secondary-options .switcher {
  font-size: 14px;
}
.search-bar__secondary-options .dropdown + .dropdown,
.search-bar__secondary-options .dropdown + .checkbox-list {
  margin-top: 12px;
}
.search-bar__secondary-options_state_open {
  display: block;
}
.search-bar__options {
  position: absolute;
  bottom: -32px;
  right: 0;
  font-size: 12px;
  text-transform: uppercase;
  color: #333;
  cursor: pointer;
  border-bottom: 1px dotted #333;
}
.search-bar__options:hover {
  color: #333;
  border-color: #333;
}
.search-bar__submit {
  padding: 16px 0;
  border-radius: 2px;
  text-transform: uppercase;
  text-align: center;
  color: #fff;
  background-color: #B83437;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.2s ease-in-out;
}
.search-bar__submit:hover {
  color: #fff;
  background-color: #852F30;
}
.search-bar .clearfix {
  display: block;
  width: 100%;
}
@media (min-width: 375px) {
  .search-bar__container {
    width: 320px;
  }
}
@media (min-width: 425px) {
  .search-bar__container {
    width: 350px;
  }
}
@media (min-width: 768px) {
  .search-bar__container {
    width: 720px;
  }
  .search-bar__main-options {
    display: flex;
    padding: 0 0 0 16px;
  }
  .search-bar__main-options .dropdown {
    flex-basis: 160px;
    margin-right: 16px;
  }
  .search-bar__main-options .checkbox-list {
    flex-basis: 180px;
    margin-right: 16px;
  }
  .search-bar__main-options .autocomplete {
    flex-basis: 240px;
  }
  .search-bar__main-options .dropdown,
  .search-bar__main-options .checkbox-list {
    border-bottom: none;
  }
  .search-bar__main-options .dropdown:before,
  .search-bar__main-options .checkbox-list:before {
    content: "";
    position: absolute;
    top: 24px;
    right: 0;
    width: 1px;
    height: 32px;
    border-right: 1px solid #ececec;
  }
  .search-bar__submit {
    flex-grow: 1;
    padding-top: 28px;
    border-radius: 0 3px 3px 0;
  }
  .search-bar__secondary-options_state_open {
    display: flex;
    flex-wrap: wrap;
  }
  .search-bar__secondary-options_state_open .dropdown,
  .search-bar__secondary-options_state_open .checkbox-list {
    flex-basis: calc(50% - 8px);
    flex-shrink: 1;
    flex-grow: 0;
    margin-bottom: 16px;
    margin-right: 16px;
  }
  .search-bar__secondary-options_state_open .dropdown:nth-child(2n),
  .search-bar__secondary-options_state_open .checkbox-list:nth-child(2n) {
    margin-right: 0;
  }
  .search-bar__secondary-options_state_open .dropdown + .dropdown,
  .search-bar__secondary-options_state_open .dropdown + .checkbox-list {
    margin-top: 0;
  }
  .search-bar__secondary-options_state_open .switcher {
    margin-top: 0;
    margin-bottom: 16px;
    margin-right: 32px;
  }
}
@media (min-width: 1024px) {
  .search-bar__container {
    width: 920px;
  }
  .search-bar__main-options .checkbox-list {
    flex-basis: 225px;
  }
  .search-bar__main-options .autocomplete {
    flex-basis: 355px;
  }
  .search-bar__secondary-options_state_open {
    display: flex;
    flex-wrap: wrap;
  }
  .search-bar__secondary-options_state_open .dropdown,
  .search-bar__secondary-options_state_open .checkbox-list {
    flex-basis: calc(25% - 12px);
    margin-right: 16px;
  }
  .search-bar__secondary-options_state_open .dropdown:nth-child(2n),
  .search-bar__secondary-options_state_open .checkbox-list:nth-child(2n) {
    margin-right: 16px;
  }
  .search-bar__secondary-options_state_open .dropdown:nth-child(4n),
  .search-bar__secondary-options_state_open .checkbox-list:nth-child(4n) {
    margin-right: 0;
  }
  .search-bar__options {
    right: 22px;
  }
}

@media (min-width: 1200px) {
  .search-bar__container {
    width: 1096px;
  }
  .search-bar__main-options .dropdown {
    flex-basis: 160px;
  }
  .search-bar__main-options .checkbox-list {
    flex-basis: 240px;
  }
  .search-bar__main-options .autocomplete {
    flex-basis: 480px;
  }
}

.hero {
  position: relative;
}
.hero__heading, .hero__subheading {
  position: relative;
  padding: 0 16px;
  max-width: 540px;
  color: #fff;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5);
  margin: 0 auto 48px;
}
.hero__heading + .hero__subheading {
  margin-top: -32px;
}
.hero__actions {
  margin-top: 16px;
}
@media (min-width: 768px) {
  .hero__heading, .hero__subheading {
    max-width: 100%;
  }
}

/*
To add new icon to the set:
1) go to the `warehouse/icons/awesome.scss`
2) find required icon
3) copy line to this file
*/
/*
To add new icon to the set:
1) go to the `warehouse/icons/ion.scss`
2) find required icon
3) copy line to this file
*/
/*
To add new icon to the set:
1) go to the `warehouse/icons/thin.scss`
2) find required icon
3) copy line to this file
*/
.hero_type_search {
  padding-top: 50px;
  flex-basis: 430px;
}
.hero_type_search .hero__heading {
  text-align: center;
}
.hero_type_search .hero__search-bar {
  padding-top: 0;
}
.hero_type_search .search-bar__options {
  color: #fff;
  border-bottom: 1px dotted #fff;
}
.hero_type_search .search-bar__options:hover {
  color: #fff;
  border-color: #fff;
}
.hero_type_search .search-bar__options:active {
  color: #fff;
  border-color: #fff;
}
@media (min-width: 768px) {
  .hero_type_search {
    flex-basis: 200px;
  }
}